<template>
  <LinkTree />
</template>

<script>
import LinkTree from '@/components/LinkTree.vue';

export default {
  name: 'HomeView',
  components: {
    LinkTree
  }
};
</script>