<template>
  <div class="bg-data pt-5">
    <h1 class="title-page">SEIKOU</h1>
    <div class="item-frame d-grid gap-2 col-12 mx-auto mt-4">
      <a v-for="(link, index) in links" :key="index" :href="link.url" :class="link.class">
        <i :class="link.icon"></i> {{ link.text }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkTree',
  data() {
    return {
      links: [
        {
          text: 'Tokopedia',
          url: 'https://tokopedia.link/bBXz1I0csMb',
          class: 'btn btn-primary btn-lg',
          icon: 'bi bi-shop'
        },
        {
          text: 'WhatsApp',
          url: 'https://wa.me/6285246659702',
          class: 'btn btn-primary btn-lg',
          icon: 'bi bi-whatsapp'
        },
        {
          text: 'Facebook Page',
          url: 'https://www.facebook.com/profile.php?id=61564097402827',
          class: 'btn btn-primary btn-lg',
          icon: 'bi bi-facebook'
        },
        {
          text: 'TikTok',
          url: 'https://www.tiktok.com/@seikoullection',
          class: 'btn btn-primary btn-lg',
          icon: 'bi bi-tiktok'
        },
        {
          text: 'Instagram',
          url: 'https://www.instagram.com/seikoullection/',
          class: 'btn btn-primary btn-lg',
          icon: 'bi bi-instagram'
        }
      ]
    };
  }
};
</script>

<style scoped>
.title-page {
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
  outline: 8px solid #fff;
  outline-offset: 4px;
  padding: 20px 40px;
  border-radius: 10px;
  letter-spacing: 10px;
  margin-bottom: 20px;
}
.bg-data {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-primary {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  border: 1px solid #fff;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}
.btn-primary:hover {
  background-color: #fff;
  color: #000;
}
.item-frame {
  width: 35%;
}

@media (max-width: 768px) {
  .item-frame {
    width: 80%;
  }
  .btn-primary {
    font-size: 16px;
  }
}
</style>