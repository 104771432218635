<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('@/assets/bg.png'), linear-gradient(to right, #000, #fff);
  color: #fff;
  height: 100vh;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
</style>
